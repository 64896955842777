export function reserved(num, multiple = 100) {
  let roundedNum = Math.round(num * multiple) // 四舍五入
  let result = roundedNum / multiple // 除以倍数
  return result
}

// 加法
export function add(num1, num2) {
  const baseNum = Math.pow(10, Math.max(getDecimalPlaces(num1), getDecimalPlaces(num2)))
  return (num1 * baseNum + num2 * baseNum) / baseNum
}

// 减法
export function subtract(num1, num2) {
  const baseNum = Math.pow(10, Math.max(getDecimalPlaces(num1), getDecimalPlaces(num2)))
  return (num1 * baseNum - num2 * baseNum) / baseNum
}

// 乘法
export function multiply(num1, num2) {
  const baseNum = Math.pow(10, getDecimalPlaces(num1) + getDecimalPlaces(num2))
  return (num1 * baseNum * num2 * baseNum) / (baseNum * baseNum)
}

// 除法
export function divide(num1, num2) {
  const baseNum = Math.pow(10, getDecimalPlaces(num2))
  return (num1 * baseNum) / (num2 * baseNum)
}

// 获取小数位数
function getDecimalPlaces(num) {
  const match = String(num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/)
  if (!match) return 0
  return Math.max(
    0,
    // Number of digits right of decimal point.
    (match[1] ? match[1].length : 0) -
      // Adjust for scientific notation.
      (match[2] ? +match[2] : 0)
  )
}
